import React, {useEffect, useState} from 'react';
import {RefSinistre} from '../../../common/RefSinistre';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {NumMission} from '../../../common/NumMission';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {MenuItem, Typography} from '@mui/material';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Adresse} from '../../../common/adresse/Adresse';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import {SuspensionVE} from '../../../common/vehicule/SuspensionVE';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {getPreremplissageDADC, postSIVForm} from '../../../../api/siv';
import {Loader} from '../../../common/Loader';
import {
  ISIVFormDAAccesTransparent,
  SIVFormModel,
} from '../../../../model/common/SIVFormModel';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {UserContext} from '../../../../context/UserContext';
import {TokenModel} from '../../../../model/common/TokenModel';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {customTheme} from '../../../../common/GlobalTheme';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {useFormik} from 'formik';
import {validationSchemaDA} from './validationSchemaDA';
import {
  getBooleanInitialValueSIV,
  getStringInitialValueSIV,
  helperText,
  helperTextWithIcon,
  onError,
} from '../../../../common/Utils';
import {FooterFormSIV} from '../FooterFormSIV';
import {CardContent} from '../CardContent';
import {InformationDeclaration} from '../InformationDeclaration';
import {stylesCommon} from '../StylesCommon';

export const FormDA = ({onChangeResponse, dataAccesTransparent, isAccesTransparent, isPreremplir}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const getItemFormSIVData = sessionStorage.getItem('formSIVData');
  const formDataStored = getItemFormSIVData ? JSON.parse(getItemFormSIVData) : '';
  const data = dataAccesTransparent as ISIVFormDAAccesTransparent;
  const {user} = React.useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [numMissionsList, setNumMissionsList] = useState<string[]>([]);
  const [showSelectMissions, setShowSelectMissions] = useState<boolean>(false);
  const [showPreremplirDA, setShowPreremplirDA] = useState<boolean>(true);
  const [showPreremplirDAOK, setShowPreremplirDAOK] = useState<boolean>(false);
  const [showPreremplirDAKO, setShowPreremplirDAKO] = useState<boolean>(false);
  const [isLoadingRemplissage, setIsLoadingRemplissage] = useState<boolean>(false);

  useEffect(() => {
    if (user.isTransparent) {
      setShowPreremplirDA(false)
    }
    if (formikDA.values.referenceSinistre?.length >= 6 && checkRefSinFormat(formikDA.values.referenceSinistre) &&
      checkRefSinSpecialChar(formikDA.values.referenceSinistre)) {
      if (!user.isTransparent && isPreremplir) {
        preremplir();
      }
      setShowPreremplirDA(false)
    }
  }, []);

  useEffect(() => {
    setShowSelectMissions(numMissionsList && numMissionsList.length > 1)
  }, [numMissionsList]);

  const initialValues = {
    referenceSinistre: getStringInitialValueSIV([formDataStored?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), data?.referenceSinistre]),
    numeroMission: getStringInitialValueSIV([formDataStored?.numeroMission,
      mission?.infoMissionDTO?.numeroMission?.trim(), data?.numeroMission]),
    titulaireActuel: {
      typePersonne: getStringInitialValueSIV([formDataStored?.titulaireActuel?.typePersonne,
        data?.titulaireActuel?.typePersonne, 'pp']),
      identificationPP: getStringInitialValueSIV([formDataStored?.titulaireActuel?.identificationPP,
        data?.titulaireActuel?.identificationPP]),
      identificationPM: getStringInitialValueSIV([formDataStored?.titulaireActuel?.identificationPM,
        data?.titulaireActuel?.identificationPM]),
      siren: getStringInitialValueSIV([formDataStored?.titulaireActuel?.siren,
        data?.titulaireActuel?.siren?.slice(0, 9)]),
      siret: getStringInitialValueSIV([formDataStored?.titulaireActuel?.siret,
        data?.titulaireActuel?.siret?.slice(0, 14)]),
    },
    titulaireNouveau: {
      raisonSociale: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.raisonSociale,
        data?.titulaireNouveau?.raisonSociale, user.raisonSocialeInfogeo]),
      siren: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.siren,
        data?.titulaireNouveau?.siren?.slice(0, 9), user.siren]),
      siret: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.siret,
        data?.titulaireNouveau?.siret?.slice(0, 14), user.siret]),
      adresse: {
        numero: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.numero,
          data?.titulaireNouveau?.adresse?.numero, user.adresse.numeroVoie]),
        indice: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.indice,
          data?.titulaireNouveau?.adresse?.indice, user.adresse.indiceRepetition]),
        type: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.type,
          data?.titulaireNouveau?.adresse?.type, user.adresse.typeVoie]),
        nom: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.nom,
          data?.titulaireNouveau?.adresse?.nom, user.adresse.libelleVoie]),
        lieuDit: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.lieuDit,
          data?.titulaireNouveau?.adresse?.lieuDit]),
        etage: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.etage,
          data?.titulaireNouveau?.adresse?.etage, user.adresse.complementAdresse]),
        immeuble: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.immeuble,
          data?.titulaireNouveau?.adresse?.immeuble]),
        codePostal: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.codePostal,
          data?.titulaireNouveau?.adresse?.codePostal, user.adresse.codePostal, user.adresse.codeCedex]),
        boitePostale: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.boitePostale,
          data?.titulaireNouveau?.adresse?.boitePostale, user.adresse.distributionSpeciale]),
        ville: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.ville,
          data?.titulaireNouveau?.adresse?.ville, user.adresse.libelleCommune, user.adresse.libelleCedex]),
        pays: getStringInitialValueSIV([formDataStored?.titulaireNouveau?.adresse?.pays,
          data?.titulaireNouveau?.adresse?.pays, 'FRANCE']),
      },
    },
    immatriculation: getStringInitialValueSIV([formDataStored?.immatriculation, data?.immatriculation]),
    numeroVin: getStringInitialValueSIV([formDataStored?.numeroVin, data?.numeroVin]),
    presenceCertificat: getBooleanInitialValueSIV([formDataStored?.presenceCertificat, data?.presenceCertificat, true]),
    numeroFormule: getStringInitialValueSIV([formDataStored?.numeroFormule, data?.numeroFormule]),
    dateCertificat: getStringInitialValueSIV([formDataStored?.dateCertificat, data?.dateCertificat]),
    dateAchat: getStringInitialValueSIV([formDataStored?.dateAchat, data?.dateAchat]),
    heureAchat: getStringInitialValueSIV([formDataStored?.heureAchat, data?.heureAchat]),
    dateDeclaration: getStringInitialValueSIV([formDataStored?.dateDeclaration, data?.dateDeclaration,
      moment().format('YYYY-MM-DD')]),
    heureDeclaration: getStringInitialValueSIV([formDataStored?.heureDeclaration, data?.heureDeclaration,
      moment().format('HH:mm')]),
    ve: getBooleanInitialValueSIV([formDataStored?.ve, data?.ve]),
    vei: getBooleanInitialValueSIV([formDataStored?.vei, data?.vei]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'DA', referenceSinistre: formikDA.values.referenceSinistre,
      numMissionsList: [formikDA.values.numeroMission],
      titulaireActuel: {isPersonnePhysique: formikDA.values.titulaireActuel.typePersonne === 'pp',
        identificationPersonne: formikDA.values.titulaireActuel.typePersonne === 'pp' ?
          formikDA.values.titulaireActuel.identificationPP : formikDA.values.titulaireActuel.identificationPM,
        siren: formikDA.values.titulaireActuel.siren, siret: formikDA.values.titulaireActuel.siret},
      nouveauTitulaire: {identificationPersonne: formikDA.values.titulaireNouveau.raisonSociale,
        siren: formikDA.values.titulaireNouveau.siren, siret: formikDA.values.titulaireNouveau.siret,
        nomVoie: formikDA.values.titulaireNouveau.adresse.nom,
        numeroVoie: formikDA.values.titulaireNouveau.adresse.numero,
        type: formikDA.values.titulaireNouveau.adresse.type,
        extension: formikDA.values.titulaireNouveau.adresse.indice,
        lieuxDit: formikDA.values.titulaireNouveau.adresse.lieuDit,
        etage: formikDA.values.titulaireNouveau.adresse.etage,
        immeuble: formikDA.values.titulaireNouveau.adresse.immeuble,
        codePostal: formikDA.values.titulaireNouveau.adresse.codePostal,
        boitePostale: formikDA.values.titulaireNouveau.adresse.boitePostale,
        localite: formikDA.values.titulaireNouveau.adresse.ville, pays: formikDA.values.titulaireNouveau.adresse.pays},
      vehicule: {numeroImmatriculation: formikDA.values.immatriculation, numeroVin: formikDA.values.numeroVin,
        isPresenceCertificat: formikDA.values.presenceCertificat, numeroFormule: formikDA.values.numeroFormule,
        dateImmaticulation: formikDA.values.dateCertificat ?
            dateToDateText(new Date(formikDA.values.dateCertificat)) : ''},
      infoAchat: {
        dateAchat: dateToDateText(new Date(formikDA.values.dateAchat)), heureAchat: formikDA.values.heureAchat,
        dateDeclarationAchat: dateToDateText(new Date(formikDA.values.dateDeclaration)),
        heureDeclarationAchat: formikDA.values.heureDeclaration, isVehiculeSuspensionVE: formikDA.values.ve,
        isVehiculeEcoIrreparable: formikDA.values.vei}};
    const tokenMission: TokenModel = {
      referenceSinistre: formikDA.values.referenceSinistre,
      numeroMission: formikDA.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    sessionStorage.setItem('tokenMission', JSON.stringify(tokenMission));
    sessionStorage.setItem('formSIVData', JSON.stringify(formikDA.values));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string, entity?: string) => () => {
    if (entity && entity !== '') {
      formikDA.setFieldTouched(`${entity}.${field}`, false, false)
    } else {
      formikDA.setFieldTouched(field, false, false)
    }
  };

  const formikDA = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaDA,
    onSubmit: onSubmit,
  });

  const onInputReferenceSinistre = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanSearch(
        e.target.value.length >= 6 && checkRefSinFormat(e.target.value) && checkRefSinSpecialChar(e.target.value),
    );
    setShowSelectMissions(false);
  };

  const onChangeNumeroMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
  };

  const disabledTitulaireNouveau = () =>
    !!(formikDA.touched.titulaireNouveau?.raisonSociale && formikDA.errors.titulaireNouveau?.raisonSociale) ||
      !!(formikDA.touched.titulaireNouveau?.siren && formikDA.errors.titulaireNouveau?.siren) ||
      !!(formikDA.touched.titulaireNouveau?.siret && formikDA.errors.titulaireNouveau?.siret) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.numero && formikDA.errors.titulaireNouveau?.adresse?.numero) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.indice && formikDA.errors.titulaireNouveau?.adresse?.indice) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.type && formikDA.errors.titulaireNouveau?.adresse?.type) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.nom && formikDA.errors.titulaireNouveau?.adresse?.nom) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.lieuDit && formikDA.errors.titulaireNouveau?.adresse?.lieuDit) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.etage && formikDA.errors.titulaireNouveau?.adresse?.etage) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.immeuble && formikDA.errors.titulaireNouveau?.adresse?.immeuble) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.codePostal &&
          formikDA.errors.titulaireNouveau?.adresse?.codePostal) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.boitePostale &&
          formikDA.errors.titulaireNouveau?.adresse?.boitePostale) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.ville && formikDA.errors.titulaireNouveau?.adresse?.ville) ||
      !!(formikDA.touched.titulaireNouveau?.adresse?.pays && formikDA.errors.titulaireNouveau?.adresse?.pays)

  const disabled = () => !!(formikDA.touched.referenceSinistre && formikDA.errors.referenceSinistre) ||
    !!(formikDA.touched.numeroMission && formikDA.errors.numeroMission) ||
    !!(formikDA.touched.titulaireActuel?.identificationPP && formikDA.errors.titulaireActuel?.identificationPP) ||
    !!(formikDA.touched.titulaireActuel?.identificationPM && formikDA.errors.titulaireActuel?.identificationPM) ||
    !!(formikDA.touched.titulaireActuel?.siren && formikDA.errors.titulaireActuel?.siren) ||
    !!(formikDA.touched.titulaireActuel?.siret && formikDA.errors.titulaireActuel?.siret) ||
    disabledTitulaireNouveau() ||
    !!(formikDA.touched.immatriculation && formikDA.errors.immatriculation) ||
    !!(formikDA.touched.numeroVin && formikDA.errors.numeroVin) ||
    !!(formikDA.touched.numeroFormule && formikDA.errors.numeroFormule) ||
    !!(formikDA.touched.dateCertificat && formikDA.errors.dateCertificat) ||
    !!(formikDA.touched.dateAchat && formikDA.errors.dateAchat) ||
    !!(formikDA.touched.heureAchat && formikDA.errors.heureAchat) ||
    !!(formikDA.touched.dateDeclaration && formikDA.errors.dateDeclaration) ||
    !!(formikDA.touched.heureDeclaration && formikDA.errors.heureDeclaration);

  const preremplirTitulaireNouveau = (res: any) => {
    formikDA.values.titulaireNouveau.raisonSociale = res.nouveauTitulaire.identificationPersonne || '';
    formikDA.values.titulaireNouveau.siren = res.nouveauTitulaire.siren || '';
    formikDA.values.titulaireNouveau.siret = res.nouveauTitulaire.siret || '';
    formikDA.values.titulaireNouveau.adresse.numero = res.nouveauTitulaire.numeroVoie || '';
    formikDA.values.titulaireNouveau.adresse.indice = res.nouveauTitulaire.extension || '';
    formikDA.values.titulaireNouveau.adresse.type = res.nouveauTitulaire.type || '';
    formikDA.values.titulaireNouveau.adresse.nom = res.nouveauTitulaire.nomVoie || '';
    formikDA.values.titulaireNouveau.adresse.lieuDit = res.nouveauTitulaire.lieuxDit || '';
    formikDA.values.titulaireNouveau.adresse.etage = res.nouveauTitulaire.etage || '';
    formikDA.values.titulaireNouveau.adresse.immeuble = res.nouveauTitulaire.immeuble || '';
    formikDA.values.titulaireNouveau.adresse.codePostal = res.nouveauTitulaire.codePostal || '';
    formikDA.values.titulaireNouveau.adresse.boitePostale = res.nouveauTitulaire.boitePostale || '';
    formikDA.values.titulaireNouveau.adresse.ville = res.nouveauTitulaire.localite || '';
  }
  const preremplir = () => {
    const tokenMission: TokenModel = {
      referenceSinistre: formikDA.values.referenceSinistre,
      numeroMission: formikDA.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    if (tokenMission) {
      setIsLoadingRemplissage(true);
      getPreremplissageDADC(tokenMission, 'A', false).then((res) => {
        const numMissionsList = res.numMissionsList;
        if (numMissionsList && numMissionsList.length > 1) {
          setNumMissionsList(numMissionsList);
          setCanSearch(false)
        } else {
          formikDA.values.titulaireActuel.typePersonne = 'pp';
          formikDA.values.titulaireActuel.identificationPP = res.titulaireActuel.identificationPersonne || '';

          preremplirTitulaireNouveau(res);

          formikDA.values.immatriculation = res.vehicule.numeroImmatriculation || '';
          formikDA.values.numeroVin = res.vehicule.numeroVin || '';
          formikDA.values.presenceCertificat = res.vehicule.isPresenceCertificat || false;
          formikDA.values.numeroFormule = res.vehicule.numeroFormule || '';
          formikDA.values.dateCertificat = res.vehicule.dateImmaticulation || '';

          formikDA.values.ve = res.infoAchat.isVehiculeSuspensionVE || false;
          formikDA.values.vei = res.infoAchat.isVehiculeEcoIrreparable || false;
          setShowPreremplirDAOK(true)
        }
        setIsLoadingRemplissage(false);
      }).catch(() => {
        setShowPreremplirDAKO(true);
        setIsLoadingRemplissage(false)
      })
    }
  };

  return (
    <div onClick={() => {
      setShowPreremplirDAKO(false);
      setShowPreremplirDAOK(false)
    }} aria-hidden={true}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'DA'}
            onChangeValue={formikDA.handleChange}
            onBlur={formikDA.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            onInput={(e) => onInputReferenceSinistre(e)}
            isMandatory={true}
            value={formikDA.values.referenceSinistre}
            onError={onError(formikDA.touched.referenceSinistre, formikDA.errors.referenceSinistre)}
            helperText={helperText(formikDA.touched.referenceSinistre, formikDA.errors.referenceSinistre)
            }
          />

          {showSelectMissions &&
            <div>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>
                N° de mission <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textfield}
                id={'numMissionsDA'}
                name={'numeroMission'}
                marginDense={true}
                value={formikDA.values.numeroMission}
                onChange={(e) => {
                  formikDA.handleChange(e);
                  onChangeNumeroMission(e)
                }}
                itemsList={numMissionsList.map((mission) =>
                  <MenuItem key={mission} value={mission}>{mission}</MenuItem>,
                )}
                onBlur={formikDA.handleBlur}
                onFocus={onFocus('numeroMission')}
                onError={onError(formikDA.touched.numeroMission, formikDA.errors.numeroMission)}
                helperText={helperText(formikDA.touched.numeroMission, formikDA.errors.numeroMission)}
              />
              {showSelectMissions && !formikDA.values.numeroMission &&
                <div className={classes.remplissageKO}>
                 Sélectionner un N° de mission.
                </div>
              }
            </div>
          }

          {!showSelectMissions &&
            <NumMission
              className={classes.textfield}
              name={'numeroMission'}
              formulaire={'DA'}
              value={formikDA.values.numeroMission}
              onChangeValue={formikDA.handleChange}
              onBlur={formikDA.handleBlur}
              onFocus={onFocus('numeroMission')}
              onError={onError(formikDA.touched.numeroMission, formikDA.errors.numeroMission)}
              helperText={helperText(formikDA.touched.numeroMission, formikDA.errors.numeroMission)
              }
            />
          }

          {showPreremplirDA &&
            <div>
              {isLoadingRemplissage ?
                <Loader className={classes.loaderPreremplir} size={30}/> :
                <ButtonBlue
                  className={classes.btnPreremplir}
                  id={'boutonPreremplirDA'}
                  libelle={'Préremplir'}
                  disabled={!canSearch}
                  onClick={() => preremplir()}
                />
              }
            </div>
          }
        </div>

        {showPreremplirDAOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }
        {showPreremplirDAKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assuré</Typography>
          <PersonnePhysiqueMorale
            name={'titulaireActuel.typePersonne'}
            formulaire={'DA'}
            onChangeValue={formikDA.handleChange}
            value={formikDA.values.titulaireActuel.typePersonne}
          />
          {formikDA.values.titulaireActuel.typePersonne === 'pp' &&
            <IdentificationPersonne
              className={`${classes.textfield} ${classes.marginTop}`}
              name={'titulaireActuel.identificationPP'}
              formulaire={'DA'}
              value={formikDA.values.titulaireActuel.identificationPP}
              typePersonne={formikDA.values.titulaireActuel.typePersonne}
              onBlur={formikDA.handleBlur}
              onFocus={onFocus('identificationPP', 'titulaireActuel')}
              onChangeValue={formikDA.handleChange}
              onError={onError(formikDA.touched.titulaireActuel?.identificationPP,
                  formikDA.errors.titulaireActuel?.identificationPP)
              }
              helperText={helperText(formikDA.touched.titulaireActuel?.identificationPP,
                  formikDA.errors.titulaireActuel?.identificationPP)
              }
            />
          }

          {formikDA.values.titulaireActuel.typePersonne === 'pm' &&
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <IdentificationPersonne
                className={classes.textfield}
                name={'titulaireActuel.identificationPM'}
                formulaire={'DA'}
                value={formikDA.values.titulaireActuel.identificationPM}
                typePersonne={formikDA.values.titulaireActuel.typePersonne}
                onBlur={formikDA.handleBlur}
                onFocus={onFocus('identificationPM', 'titulaireActuel')}
                onChangeValue={formikDA.handleChange}
                onError={onError(formikDA.touched.titulaireActuel?.identificationPM,
                    formikDA.errors.titulaireActuel?.identificationPM)
                }
                helperText={helperText(formikDA.touched.titulaireActuel?.identificationPM,
                    formikDA.errors.titulaireActuel?.identificationPM)
                }
              />
              <Siren
                className={classes.textfield}
                id={'sirenTitulaire'}
                name={'titulaireActuel.siren'}
                formulaire={'DA'}
                value={formikDA.values.titulaireActuel.siren}
                isMandatory={false}
                onChangeValue={formikDA.handleChange}
                onBlur={formikDA.handleBlur}
                onFocus={onFocus('siren', 'titulaireActuel')}
                onError={onError(formikDA.touched.titulaireActuel?.siren, formikDA.errors.titulaireActuel?.siren)}
                helperText={helperTextWithIcon(formikDA, 'siren', 'titulaireActuel', classes.helpIcon)}
              />
              <Siret
                className={classes.textfield}
                id={'siretTitulaire'}
                name={'titulaireActuel.siret'}
                formulaire={'DA'}
                value={formikDA.values.titulaireActuel.siret}
                isMandatory={false}
                onChangeValue={formikDA.handleChange}
                onBlur={formikDA.handleBlur}
                onFocus={onFocus('siret', 'titulaireActuel')}
                onError={onError(formikDA.touched.titulaireActuel?.siret, formikDA.errors.titulaireActuel?.siret)}
                helperText={helperTextWithIcon(formikDA, 'siret', 'titulaireActuel', classes.helpIcon)}
              />
            </div>
          }
        </div>
      </div>

      <CardContent titre={`Identité du nouveau titulaire : Assureur`}
        content={
          <div className={classes.flex}>
            <RaisonSociale
              className={classes.textfield}
              name={'titulaireNouveau.raisonSociale'}
              formulaire={'DA'}
              value={formikDA.values.titulaireNouveau.raisonSociale}
              onChangeValue={formikDA.handleChange}
              onBlur={formikDA.handleBlur}
              onFocus={onFocus('raisonSociale', 'titulaireNouveau')}
              onError={onError(formikDA.touched.titulaireNouveau?.raisonSociale,
                  formikDA.errors.titulaireNouveau?.raisonSociale)}
              helperText={helperText(formikDA.touched.titulaireNouveau?.raisonSociale,
                  formikDA.errors.titulaireNouveau?.raisonSociale)
              }
            />
            <Siren
              className={classes.textfield}
              id={'sirenNewTitulaire'}
              name={'titulaireNouveau.siren'}
              formulaire={'DA'}
              value={formikDA.values.titulaireNouveau.siren}
              isMandatory={false}
              onChangeValue={formikDA.handleChange}
              onBlur={formikDA.handleBlur}
              onFocus={onFocus('siren', 'titulaireNouveau')}
              onError={onError(formikDA.touched.titulaireNouveau?.siren, formikDA.errors.titulaireNouveau?.siren)}
              helperText={helperTextWithIcon(formikDA, 'siren', 'titulaireNouveau', classes.helpIcon)}
            />
            <Siret
              className={classes.textfield}
              id={'siretNewTitulaire'}
              name={'titulaireNouveau.siret'}
              formulaire={'DA'}
              value={formikDA.values.titulaireNouveau.siret}
              isMandatory={formikDA.values.titulaireNouveau.adresse.pays === 'FRANCE'}
              onChangeValue={formikDA.handleChange}
              onBlur={formikDA.handleBlur}
              onFocus={onFocus('siret', 'titulaireNouveau')}
              onError={onError(formikDA.touched.titulaireNouveau?.siret, formikDA.errors.titulaireNouveau?.siret)}
              helperText={helperTextWithIcon(formikDA, 'siret', 'titulaireNouveau', classes.helpIcon)}
            />
          </div>
        }
      />

      <CardContent titre={`Coordonnées du nouveau titulaire : Assureur`}
        content={
          <Adresse
            formulaire={'DA'}
            formik={formikDA}
            entity={'titulaireNouveau.adresse'}
            formikValues={formikDA.values.titulaireNouveau.adresse}
            formikTouched={formikDA.touched.titulaireNouveau?.adresse}
            formikErrors={formikDA.errors.titulaireNouveau?.adresse}
          />
        }
      />

      <CardContent titre={`Véhicule`}
        content={
          <Vehicule
            formulaire={'DA'}
            formik={formikDA}
            entity={''}
            formikValues={{immatriculation: formikDA.values.immatriculation, numeroVin: formikDA.values.numeroVin,
              presenceCertificat: formikDA.values.presenceCertificat, numeroFormule: formikDA.values.numeroFormule,
              dateCertificat: formikDA.values.dateCertificat}
            }
            formikTouched={formikDA.touched}
            formikErrors={formikDA.errors}
          />
        }
      />

      <CardContent titre={`Information sur la déclaration d'achat`}
        content={<InformationDeclaration formulaire={'DA'} formik={formikDA} onFocus={onFocus}/>}/>

      <CardContent titre={`Information expertise`}
        content={<SuspensionVE
          nameVE={'ve'}
          nameVEI={'vei'}
          isVE={formikDA.values.ve}
          isVEI={formikDA.values.vei}
          onChangeValue={formikDA.handleChange}
        />}
      />

      <FooterFormSIV
        responseReturn={responseReturn}
        isLoading={isLoading}
        disabled={disabled()}
        onClick={formikDA.submitForm}/>
    </div>
  )
};
