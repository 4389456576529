import React, {useState} from 'react';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';
import {RefSinistre} from '../../../common/RefSinistre';
import {InputDate} from '../../../common/InputDate';
import {Hour} from '../../../common/Hour';
import {postSIVForm} from '../../../../api/siv';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {ISIVFormITAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getStringInitialValueSIV, helperText, helperTextWithIcon, onError} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaIT} from './validationSchemaIT';
import {FooterFormSIV} from '../FooterFormSIV';
import {stylesCommon} from '../StylesCommon';

export const FormIT = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const getItemFormSIVData = sessionStorage.getItem('formSIVData');
  const formDataStored = getItemFormSIVData ? JSON.parse(getItemFormSIVData) : '';
  const data = dataAccesTransparent as ISIVFormITAccesTransparent;
  const [isLoading, setIsLoading] = useState(false);

  const initialValuesIT = {
    immatriculation: getStringInitialValueSIV([formDataStored?.immatriculation,
      mission?.infoMissionDTO?.immatriculation, data?.immatriculation]),
    referenceSinistre: getStringInitialValueSIV([formDataStored?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), data?.referenceSinistre]),
    date: getStringInitialValueSIV([formDataStored?.date, mission?.gestionDTO?.informationSinistreDTO?.dateSinistre,
      data?.date]),
    heure: getStringInitialValueSIV([formDataStored?.heure, mission?.gestionDTO?.informationSinistreDTO?.heureSinistre,
      data?.heure]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'IT', vehicule: {numeroImmatriculation: formikIT.values.immatriculation},
      referenceSinistre: formikIT.values.referenceSinistre,
      dateSinistre: dateToDateText(new Date(formikIT.values.date)),
      heureSinistre: formikIT.values.heure};
    sessionStorage.setItem('formSIVData', JSON.stringify(formikIT.values));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        }).catch((error) => {
          onChangeResponse(error.response)
        }).finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formikIT.setFieldTouched(field, false, false)
  };

  const formikIT = useFormik({
    initialValues: initialValuesIT,
    validationSchema: validationSchemaIT,
    onSubmit: onSubmit,
  });

  const disabled = !!(formikIT.touched.immatriculation && formikIT.errors.immatriculation) ||
    !!(formikIT.touched.referenceSinistre && formikIT.errors.referenceSinistre) ||
    !!(formikIT.touched.date && formikIT.errors.date) ||
    !!(formikIT.touched.heure && formikIT.errors.heure);

  return (
    <div>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <Immatriculation
            className={classes.textfield}
            name={'immatriculation'}
            formulaire={'IT'}
            value={formikIT.values.immatriculation}
            onChangeValue={formikIT.handleChange}
            onBlur={formikIT.handleBlur}
            onFocus={onFocus('immatriculation')}
            onError={onError(formikIT.touched.immatriculation, formikIT.errors.immatriculation)}
            helperText={helperTextWithIcon(formikIT, 'immatriculation', '', classes.helpIcon)}
          />
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'IT'}
            onChangeValue={formikIT.handleChange}
            onBlur={formikIT.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={true}
            value={formikIT.values.referenceSinistre}
            onError={onError(formikIT.touched.referenceSinistre, formikIT.errors.referenceSinistre)}
            helperText={helperText(formikIT.touched.referenceSinistre, formikIT.errors.referenceSinistre)}
          />
        </div>
        <div className={`${classes.flex} ${classes.line}`}>
          <InputDate
            className={classes.textfieldSmall}
            name={'date'}
            id={'date'}
            formulaire={'IT'}
            isMandatory={true}
            title={'Date du sinistre'}
            onBlur={formikIT.handleBlur}
            onFocus={onFocus('date')}
            onChangeValue={formikIT.handleChange}
            value={formikIT.values.date}
            onError={onError(formikIT.touched.date, formikIT.errors.date)}
            helperText={helperText(formikIT.touched.date, formikIT.errors.date)}
          />
          <Hour
            className={classes.textfieldSmall}
            name={'heure'}
            id={'heure'}
            formulaire={'IT'}
            isMandatory={true}
            title={'Heure du sinistre'}
            value={formikIT.values.heure}
            onChangeValue={formikIT.handleChange}
            onBlur={formikIT.handleBlur}
            onFocus={onFocus('heure')}
            onError={onError(formikIT.touched.heure, formikIT.errors.heure)}
            helperText={helperText(formikIT.touched.heure, formikIT.errors.heure)}
          />
        </div>
      </div>

      <FooterFormSIV
        isLoading={isLoading}
        disabled={disabled}
        onClick={formikIT.submitForm}/>
    </div>
  )
};
