import React, {useState} from 'react';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';
import {RefSinistre} from '../../../common/RefSinistre';
import {PoliceAss} from '../../../common/PoliceAss';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {Nom} from '../../../common/personne/Nom';
import {Prenom} from '../../../common/personne/Prenom';
import {SocieteCommerciale} from '../../../common/personne/SocieteCommerciale';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {ISIVFormCTAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {postSIVForm} from '../../../../api/siv';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {
  getBooleanInitialValueSIV,
  getStringInitialValueSIV,
  helperText,
  helperTextWithIcon,
  onError,
} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaCT} from './validationSchemaCT';
import {FooterFormSIV} from '../FooterFormSIV';
import {stylesCommon} from '../StylesCommon';

export const FormCT = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const getItemFormSIVData = sessionStorage.getItem('formSIVData');
  const formDataStored = getItemFormSIVData ? JSON.parse(getItemFormSIVData) : '';
  const data = dataAccesTransparent as ISIVFormCTAccesTransparent;
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    immatriculation: getStringInitialValueSIV([formDataStored?.immatriculation,
      mission?.infoMissionDTO?.immatriculation, data?.immatriculation]),
    policeAssurance: getStringInitialValueSIV([formDataStored?.policeAssurance, mission?.societaireDTO?.numero,
      data?.policeAssurance]),
    referenceSinistre: getStringInitialValueSIV([formDataStored?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), data?.referenceSinistre]),
    typePersonne: getStringInitialValueSIV([formDataStored?.typePersonne, data?.typePersonne, 'pp']),
    nom: getStringInitialValueSIV([formDataStored?.nom, data?.nom]),
    nomUsage: getStringInitialValueSIV([formDataStored?.nomUsage, data?.nomUsage]),
    prenom: getStringInitialValueSIV([formDataStored?.prenom, data?.prenom]),
    societeCommerciale: getBooleanInitialValueSIV([formDataStored?.societeCommerciale, data?.societeCommerciale]),
    raisonSociale: getStringInitialValueSIV([formDataStored?.raisonSociale, data?.raisonSociale]),
    siren: getStringInitialValueSIV([formDataStored?.siren, data?.siren?.slice(0, 9)]),
    siret: getStringInitialValueSIV([formDataStored?.siret, data?.siret?.slice(0, 14)]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'CT', vehicule: {numeroImmatriculation: formikCT.values.immatriculation},
      referenceSinistre: formikCT.values.referenceSinistre, numeroPoliceAssurance: formikCT.values.policeAssurance,
      titulaireActuel: {isPersonnePhysique: formikCT.values.typePersonne === 'pp',
        isSocieteCommerciale: formikCT.values.societeCommerciale,
        identificationPersonne: formikCT.values.typePersonne === 'pm' ? formikCT.values.raisonSociale : '',
        siren: formikCT.values.typePersonne === 'pm' ? formikCT.values.siren : '',
        siret: formikCT.values.typePersonne === 'pm' ? formikCT.values.siret : '',
        nom: formikCT.values.typePersonne === 'pp' ? formikCT.values.nom : '',
        nomUsage: formikCT.values.typePersonne === 'pp' ? formikCT.values.nomUsage : '',
        prenom: formikCT.values.typePersonne === 'pp' ? formikCT.values.prenom : ''},
    };
    sessionStorage.setItem('formSIVData', JSON.stringify(formikCT.values));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        }).catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formikCT.setFieldTouched(field, false, false)
  };

  const formikCT = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaCT,
    onSubmit: onSubmit,
  });

  const disabled = () => !!(formikCT.touched.immatriculation && formikCT.errors.immatriculation) ||
    !!(formikCT.touched.policeAssurance && formikCT.errors.policeAssurance) ||
    !!(formikCT.touched.referenceSinistre && formikCT.errors.referenceSinistre) ||
    !!(formikCT.touched.nom && formikCT.errors.nom) ||
    !!(formikCT.touched.nomUsage && formikCT.errors.nomUsage) ||
    !!(formikCT.touched.prenom && formikCT.errors.prenom) ||
    !!(formikCT.touched.raisonSociale && formikCT.errors.raisonSociale) ||
    !!(formikCT.touched.siren && formikCT.errors.siren) ||
    !!(formikCT.touched.siret && formikCT.errors.siret);

  return (
    <div>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <Immatriculation
            className={classes.textfield}
            name={'immatriculation'}
            formulaire={'CT'}
            value={formikCT.values.immatriculation}
            onChangeValue={formikCT.handleChange}
            onBlur={formikCT.handleBlur}
            onFocus={onFocus('immatriculation')}
            onError={onError(formikCT.touched.immatriculation, formikCT.errors.immatriculation)}
            helperText={helperTextWithIcon(formikCT, 'immatriculation', '', classes.helpIcon)}
          />
          <PoliceAss
            className={classes.textfield}
            name={'policeAssurance'}
            formulaire={'CT'}
            value={formikCT.values.policeAssurance}
            onChangeValue={formikCT.handleChange}
            onBlur={formikCT.handleBlur}
            onFocus={onFocus('policeAssurance')}
            onError={onError(formikCT.touched.policeAssurance, formikCT.errors.policeAssurance)}
            helperText={helperText(formikCT.touched.policeAssurance, formikCT.errors.policeAssurance)
            }
          />
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'CT'}
            onChangeValue={formikCT.handleChange}
            onBlur={formikCT.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={false}
            value={formikCT.values.referenceSinistre}
            onError={onError(formikCT.touched.referenceSinistre, formikCT.errors.referenceSinistre)}
            helperText={helperText(formikCT.touched.referenceSinistre, formikCT.errors.referenceSinistre)
            }
          />
        </div>

        <PersonnePhysiqueMorale
          className={classes.marginTop}
          name={'typePersonne'}
          formulaire={'CT'}
          onChangeValue={formikCT.handleChange}
          value={formikCT.values.typePersonne}
        />

        {formikCT.values.typePersonne === 'pp' &&
          <div className={`${classes.flex} ${classes.marginTop}`}>
            <Nom
              className={classes.textfield}
              name={'nom'}
              value={formikCT.values.nom}
              fullWidth={true}
              formulaire={'CT'}
              nomUsage={false}
              onChangeValue={formikCT.handleChange}
              onBlur={formikCT.handleBlur}
              onFocus={onFocus('nom')}
              onError={onError(formikCT.touched.nom, formikCT.errors.nom)}
              helperText={helperText(formikCT.touched.nom, formikCT.errors.nom)}
              maxLength={80}
            />
            <Nom
              className={classes.textfield}
              name={'nomUsage'}
              value={formikCT.values.nomUsage}
              fullWidth={true}
              formulaire={'CT'}
              nomUsage={true}
              onChangeValue={formikCT.handleChange}
              onBlur={formikCT.handleBlur}
              onFocus={onFocus('nomUsage')}
              onError={onError(formikCT.touched.nomUsage, formikCT.errors.nomUsage)}
              helperText={helperText(formikCT.touched.nomUsage, formikCT.errors.nomUsage)}
              maxLength={80}
            />
            <Prenom
              className={classes.textfield}
              name={'prenom'}
              value={formikCT.values.prenom}
              fullWidth={true}
              formulaire={'CT'}
              onChangeValue={formikCT.handleChange}
              onBlur={formikCT.handleBlur}
              onFocus={onFocus('prenom')}
              onError={onError(formikCT.touched.prenom, formikCT.errors.prenom)}
              helperText={helperText(formikCT.touched.prenom, formikCT.errors.prenom)}
              maxLength={80}
            />
          </div>
        }

        {formikCT.values.typePersonne === 'pm' &&
          <React.Fragment>
            <SocieteCommerciale
              className={classes.marginTop}
              name={'societeCommerciale'}
              value={formikCT.values.societeCommerciale}
              onChangeValue={formikCT.handleChange}
            />
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <RaisonSociale
                className={classes.textfield}
                name={'raisonSociale'}
                value={formikCT.values.raisonSociale}
                formulaire={'CT'}
                onChangeValue={formikCT.handleChange}
                onBlur={formikCT.handleBlur}
                onFocus={onFocus('raisonSociale')}
                onError={onError(formikCT.touched.raisonSociale, formikCT.errors.raisonSociale)}
                helperText={helperText(formikCT.touched.raisonSociale, formikCT.errors.raisonSociale)
                }
              />
              <Siren
                className={classes.textfield}
                id={'siren'}
                name={'siren'}
                value={formikCT.values.siren}
                formulaire={'CT'}
                isMandatory={formikCT.values.societeCommerciale}
                onChangeValue={formikCT.handleChange}
                onBlur={formikCT.handleBlur}
                onFocus={onFocus('siren')}
                onError={onError(formikCT.touched.siren, formikCT.errors.siren)}
                helperText={helperTextWithIcon(formikCT, 'siren', '', classes.helpIcon)}
              />
              <Siret
                className={classes.textfield}
                id={'siret'}
                name={'siret'}
                value={formikCT.values.siret}
                formulaire={'CT'}
                isMandatory={formikCT.values.societeCommerciale}
                onChangeValue={formikCT.handleChange}
                onBlur={formikCT.handleBlur}
                onFocus={onFocus('siret')}
                onError={onError(formikCT.touched.siret, formikCT.errors.siret)}
                helperText={helperTextWithIcon(formikCT, 'siret', '', classes.helpIcon)}
              />
            </div>
          </React.Fragment>
        }
      </div>

      <FooterFormSIV
        responseReturn={responseReturn}
        isLoading={isLoading}
        disabled={disabled()}
        onClick={formikCT.submitForm}/>
    </div>
  )
};
