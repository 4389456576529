import React, {useEffect} from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import XMLParser from 'react-xml-parser';
import {CatchErreurSIV} from '../CatchErreurSIV';
import {FooterReponseSIV} from './FooterReponseSIV';
import {SIVReqRepModel} from '../../../../../model/common/SIVReqRepModel';
import {Typography} from '@mui/material';
import {ErreurSIV} from './ErreurSIV';
import {ReponseCT} from './ReponseCT';
import {ReponseIR} from './ReponseIR';
import {ReponseIT} from './ReponseIT';
import {ReponseSA} from './ReponseSA';
import {ReponseDADC} from './ReponseDADC';
import {stylesCommon} from '../StylesCommon';
import {AxiosResponse} from 'axios';

interface ReponseCommonSIVProps {
  sIVReqRepModel: SIVReqRepModel,
  formulaire: string,
  setResponse?: (response: AxiosResponse | null) => void,
  setIsRetourErreurSIV?: (isRetourErreurSIV: boolean) => void
}

const titre = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'Réponse - Caractéristiques techniques du véhicule'
    case 'SA': return 'Réponse - Situation administrative du véhicule'
    case 'DC': return 'Réponse - Déclaration de cession du véhicule à un professionnel'
    case 'IR': return 'Réponse - Inscription de refus de cession du véhicule'
    case 'DA': return `Réponse - Déclaration d'achat du véhicule à l'assuré`
    case 'IT': return 'Réponse - Identification du titulaire du véhicule'
    default: return ''
  }
}

const baliseContent = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'liste_dossiers'
    case 'SA': return 'consult_ass_situation_administrative'
    case 'DC': return 'declaration_ass_sur_vehicule_cession'
    case 'IR': return 'opposition_ve_ass_sur_vehicule'
    case 'DA': return 'declaration_ass_sur_vehicule_achat'
    case 'IT': return 'liste_dossiers'
    default: return ''
  }
}

const titreCatchErreur = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'Caractéristiques techniques du véhicule'
    case 'SA': return 'Situation administrative du véhicule'
    case 'DC': return 'Acquittement de la déclaration de cession'
    case 'IR': return 'Acquittement du refus de cession'
    case 'DA': return `Acquittement de la déclaration d'achat`
    case 'IT': return 'Identification du titulaire du véhicule'
    default: return ''
  }
}

const documentTitle = (formulaire: string) => {
  switch (formulaire) {
    case 'CT': return 'Réponse CT'
    case 'SA': return 'Réponse SA'
    case 'DC': return 'Réponse DC'
    case 'IR': return 'Réponse RC'
    case 'DA': return `Réponse DA`
    case 'IT': return 'Réponse IT'
    default: return ''
  }
}

export const ReponseCommonSIV = (props: ReponseCommonSIVProps) => {
  const classes = useEmotionStyles(stylesCommon);
  const {content, from, fromPath, token} = props.sIVReqRepModel
  const {formulaire, setResponse, setIsRetourErreurSIV} = props

  useEffect(() => {
    if (from === 'historique') {
      document.title = documentTitle(formulaire)
    }
  }, []);

  try {
    const data = new XMLParser().parseFromString(content.contenuMessage, 'text/xml');
    const prefix = (() => {
      if (data.getElementsByTagName('siv:code_traitement').length !== 0) return 'siv:';
      if (data.getElementsByTagName('ns1:code_traitement').length !== 0) return 'ns1:';
      return '';
    })();

    return (
      <div className={classes.container}>
        <Typography className={classes.titre}>{titre(formulaire)}</Typography>
        {(data.getElementsByTagName(prefix + 'code_traitement').length !== 0 &&
          data.getElementsByTagName(prefix + 'code_traitement')[0].value === '0' &&
          data.getElementsByTagName(prefix + baliseContent(formulaire)).length !== 0) ?
          <React.Fragment>
            {formulaire == 'CT' &&
              <ReponseCT data={data} prefix={prefix} formulaire={formulaire}/>
            }
            {formulaire == 'IR' &&
              <ReponseIR data={data} prefix={prefix} formulaire={formulaire}/>
            }
            {formulaire == 'IT' &&
              <ReponseIT data={data} prefix={prefix} formulaire={formulaire}/>
            }
            {formulaire == 'SA' &&
              <ReponseSA data={data} prefix={prefix} formulaire={formulaire}/>
            }
            {(formulaire == 'DA' || formulaire == 'DC') &&
              <ReponseDADC data={data} prefix={prefix} formulaire={formulaire}/>
            }
          </React.Fragment> :
          (data.getElementsByTagName(prefix + 'description_erreur').length !== 0 &&
            <ErreurSIV data={data} prefix={prefix}/>
          )
        }
        <FooterReponseSIV
          formulaire={formulaire}
          from={from}
          fromPath={fromPath}
          token={token}
          {...(setResponse && {setResponse: setResponse})}
          {...(setIsRetourErreurSIV && {setIsRetourErreurSIV: setIsRetourErreurSIV})}
        />
      </div>
    )
  } catch (e) {
    return (
      <CatchErreurSIV
        titre={titreCatchErreur(formulaire)}
        footerSiv={<FooterReponseSIV
          from={from}
          fromPath={fromPath}
          token={token}
          {...(setResponse && {setResponse: setResponse})}
          {...(setIsRetourErreurSIV && {setIsRetourErreurSIV: setIsRetourErreurSIV})}
        />}
      />
    )
  }
};
